import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, Tooltip, IconButton, useTheme } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import People from './People';
import Orders from './Orders';
// import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AssesPopover from './AssesPopover';
import Notifications from './Notifications';
import AccountPopover from './AccountPopover';
import DowngradePopover from './DowngradePopover';
// routes
import { PATH_ACTIVECAMPAIGNS, PATH_EVENTS, PATH_STORE, PATH_TOOLS } from '../../../routes/generalPaths';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  boxShadow: 'none',
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
};


export default function DashboardHeader({ onOpenSidebar }) {
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  const { isCollapse } = useCollapseDrawer();
  // const { updatedAt } = useDataInformation();
  const theme = useTheme();

  const {
    user,
    username,
    userCore,
    userRole,
    userBranch,
    userDepartment,
    setAssignedCore,
    setAssignedUsername,
    setAssignedUserRole,
    setAssignedUserBranch,
    setAssignedDepartment
  } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const handleBack = () => {
    setAssignedCore(null);
    localStorage.removeItem('assignedCore');

    setAssignedDepartment(null);
    localStorage.removeItem('assignedDepartment');

    setAssignedUserRole(null);
    localStorage.removeItem('assignedUserRole');

    setAssignedUserBranch(null);
    localStorage.removeItem('assignedUserBranch');

    setAssignedUsername(null);
    localStorage.removeItem('assignedUsername');

    navigate('/');
  };

  return (
    <RootStyle isCollapse={isCollapse}>
      <ToolbarStyle>
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Button
          variant='outlined'
          onClick={() => window.open('https://comparaativos.com.br', '_blank')}
          startIcon={<img src='/favicon/favicon_ca.png' alt='Compara ativos' style={{ width: 24, height: 24 }} />}
        >
          Compara Ativos
        </Button>

        {/* updatedAt !== false && <UpdatedAt /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>

          {isDesktop && localStorage.getItem('assignedUsername') && (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
            <Button
              variant="contained"
              disabled
              startIcon={<Iconify icon={'mdi:account-arrow-up'} />}
              style={{
                boxShadow: 'none',
                ...theme.palette.mode === 'light' && { color: theme.palette.primary.main }
              }}
            >
              {username}
            </Button>
          )}

          {localStorage.getItem('assignedUserBranch') && (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
            <Button
              disabled
              variant="contained"
              startIcon={<Iconify icon={'mdi:account-arrow-up'} />}
              style={{
                boxShadow: 'none',
                ...theme.palette.mode === 'light' && { color: theme.palette.primary.main }
              }}
            >
              {userBranch}
            </Button>
          )}

          {isDesktop && localStorage.getItem('assignedUserRole') && (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
            <Button
              disabled
              variant="contained"
              startIcon={<Iconify icon={'mdi:account-arrow-up'} />}
              style={{
                boxShadow: 'none',
                ...theme.palette.mode === 'light' && { color: theme.palette.primary.main }
              }}
            >
              {userRole}
            </Button>
          )}

          {isDesktop && localStorage.getItem('assignedDepartment') && (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
            <Button
              disabled
              variant="contained"
              startIcon={<Iconify icon={'mdi:account-arrow-up'} />}
              style={{
                boxShadow: 'none',
                ...theme.palette.mode === 'light' && { color: theme.palette.primary.main }
              }}
            >
              {userDepartment}
            </Button>
          )}

          {isDesktop && localStorage.getItem('assignedCore') && (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
            <Button
              disabled
              variant="contained"
              startIcon={<Iconify icon={'mdi:account-arrow-up'} />}
              style={{
                boxShadow: 'none',
                ...theme.palette.mode === 'light' && { color: theme.palette.primary.main }
              }}
            >
              {userCore}
            </Button>
          )}

          {
            (localStorage.getItem('assignedUsername') ||
              localStorage.getItem('assignedUserRole') ||
              localStorage.getItem('assignedUserBranch') ||
              localStorage.getItem('assignedCore') ||
              localStorage.getItem('assignedDepartment')) &&
            (user?.attributes?.['custom:access_level'] === 'DEV' || user?.attributes?.['custom:access_level'] === 'FOLLOW') && (
              <Tooltip title="Retornar como usuário principal">
                <IconButton onClick={handleBack} color='primary'>
                  <Iconify icon="mdi:account-arrow-down" />
                </IconButton>
              </Tooltip>
            )}

          {user?.attributes?.['custom:access_level'] === 'DEV' && <DowngradePopover />}

          {user?.attributes?.['custom:access_level'] === 'FOLLOW' && <AssesPopover />}

          <Stack direction="row" spacing={0.5}>            
            <Notifications />

            {/* <Tooltip title="Campanhas Ativas">
              <IconButtonAnimate
                onClick={() => navigate(PATH_ACTIVECAMPAIGNS.list)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <Iconify icon="lucide:chart-gantt" sx={{ fontSize: 26 }} />
              </IconButtonAnimate>
            </Tooltip> */}

            <People />

            <Tooltip title="Valor Store">
              <IconButtonAnimate
                onClick={() => navigate(PATH_STORE.catalog)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <Iconify icon="ion:bag-handle" sx={{ fontSize: 26 }} />
              </IconButtonAnimate>
            </Tooltip>

            <Tooltip title="Controle de Eventos">
              <IconButtonAnimate
                onClick={() => navigate(PATH_EVENTS.list)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <Iconify icon="fluent:task-list-square-rtl-24-regular" sx={{ fontSize: 26 }} />
              </IconButtonAnimate>
            </Tooltip>

            <Orders />

            <Tooltip title="Links úteis">
              <IconButtonAnimate
                onClick={() => navigate(PATH_TOOLS.links)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <Iconify icon="mdi-link-variant" />
              </IconButtonAnimate>
            </Tooltip>
            {/* 
            <Tooltip title="Documentos Compartilhados">
              <IconButtonAnimate
                onClick={() => navigate(PATH_TOOLS.sharing.root)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <Iconify icon="ant-design:share-alt-outlined" />
              </IconButtonAnimate>
            </Tooltip> */}

            <Tooltip title="Buscar cliente">
              <IconButtonAnimate
                onClick={() => navigate(PATH_TOOLS.find)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 38,
                  height: 38,
                }}
              >
                <Iconify icon="material-symbols:search-rounded" />
              </IconButtonAnimate>
            </Tooltip>

            <Tooltip title="Gestão à Vista">
              <IconButtonAnimate
                onClick={() => window.open(PATH_TOOLS.gv.fullscreen, '_blank')}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 38,
                  height: 38,
                }}
              >
                <Iconify icon="pajamas:monitor" />
              </IconButtonAnimate>
            </Tooltip>

            <Tooltip title="Agendamento de salas">
              <IconButtonAnimate
                onClick={() => navigate(PATH_TOOLS.scheduler)}
                sx={{
                  padding: 0,
                  margin: 0,
                  width: 38,
                  height: 38,
                }}
              >
                <Iconify icon="bi:calendar-week" />
              </IconButtonAnimate>
            </Tooltip>
          </Stack>

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
