import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/dashboard') ||
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userRoutes }),

    // {
    //   path: 'dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/base" replace />, index: true },
    //     { path: 'base', element: <GeneralBase /> },
    //   ],
    // },

    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/digital" replace />, index: true },
        { path: 'digital', element: <PainelDigital /> },
        { path: 'gerencial', element: <Gerencial /> },
        { path: 'bases', element: <Bases /> },
        // { path: 'ranking', element: <RankingAdvisor /> },
        // {
        //   path: 'ranking',
        //   children: [
        //     { element: <Navigate to="/report/ranking/ranking" replace />, index: true },
        //     {
        //       path: 'follow-up',
        //       children: [
        //         { element: <Navigate to="/report/ranking/follow-up/advisor" replace />, index: true },
        //         { path: 'advisor', element: <RankingAdvisor /> },
        //       ]
        //     },
        //   ],
        // },

        {
          path: 'performance',
          children: [
            { element: <Navigate to="/report/performance/receita" replace />, index: true },
            {
              path: 'receita',
              children: [
                { element: <Navigate to="/report/performance/receita/advisor" replace />, index: true },
                { path: 'advisor', element: <Receita /> },
              ]
            },
          ],
        },

        {
          path: 'esforco',
          children: [
            { element: <Navigate to="/report/esforco/crmadvisor" replace />, index: true },
            { path: 'crmadvisor', element: <CRMAdvisor /> }
          ],
        },

        {
          path: 'reunioes',
          children: [
            { element: <Navigate to="/report/reunioes/newN3" replace />, index: true },
            { path: 'newN3', element: <NewN3 />, index: true },
          ],
        },
      ],
    },
  ]);
}

// DASHBOARD
// const GeneralBase = Loadable(lazy(() => import('../../../../pages/VI/general/GeneralBase')));

// REPORT
const Gerencial = Loadable(lazy(() => import('../../../../pages/VI/DIGI/general/power-bi/Gerencial')));
const Bases = Loadable(lazy(() => import('../../../../pages/VI/DIGI/general/power-bi/Bases')));
const PainelDigital = Loadable(lazy(() => import('../../../../pages/VI/DIGI/general/power-bi/PainelDigital')));
// const RankingAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
const CRMAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMAdvisor')));
const NewN3 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/reunioes/NewN3')));
const Receita = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Receita')));
