import { Suspense, lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
// config
import reportRules from '../reportRules.json';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/in') ||
      pathname.includes('/okr') ||
      pathname.includes('/tools') ||
      pathname.includes('/export')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function defaultRouter({ defaultRoute, user, username, userRole, userDepartment, userBranch = '', userRoutes = [] }) {
  const powerBiExport = reportRules.powerBiExport.includes(username) || (userRole === 'DEV');
  // const okrGeneral = reportRules.okrGeneral.includes(username) || (userRole === 'DEV');
  const pipeAdvisor = ((userRole === 'ADVISOR' || userRole === 'FARMER') && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV';
  const pipeLeader = ((userRole === 'LEADER' || userRole === 'FLEADER') && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV';
  const pipeManager = (userRole === 'MANAGER' && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV' || userRoutes?.includes('pipemanager');
  const pipeHead = (userRole === 'HEAD' && (userDepartment === 'ASSES' || userDepartment === 'MELCONT')) || userRole === 'DEV';

  const isAdmin = (userRole === 'ADMIN') || (userRole === 'DEV');
  const isAssess = (userDepartment === 'ASSES' && userRole !== 'MANAGER') || (userDepartment === 'DADOS') || (userDepartment === 'DIGI') || isAdmin;
  const uploadEnabled = user?.attributes?.['custom:upload_enabled'] === 'TRUE';
  const sharingEnabled = user?.attributes?.['custom:sharing_enabled'] === 'TRUE';
  const assesManager = userDepartment === 'ASSES' && userRole === 'MANAGER';
  const assesHead = userDepartment === 'ASSES' && userRole === 'HEAD';
  // console.log(userRoutes)

  return [

    {
      path: 'home',
      element: <DashboardLayout />,
      children: [
        { element: <Home />, index: true },
      ]
    },

    {
      path: 'orgchart',
      element: <DashboardLayout />,
      children: [
        { element: <OrgChart />, index: true },
      ]
    },

    {
      path: 'orgstructure',
      element: <DashboardLayout />,
      children: [
        { element: <OrgStructure />, index: true },
      ]
    },

    {
      path: 'download',
      element: <DashboardLayout />,
      children: [
        { element: <Download />, index: true },
      ]
    },

    // ROA
    ...userDepartment === 'DADOS' || userRoutes?.includes('analiseRoa') ? [
      {
        path: 'roa',
        element: <DashboardLayout />,
        children: [
          { element: <Roa />, index: true },
        ]
      }
    ] : [],

    // MESA BANKING
    ...userDepartment === 'BANKING' || userRoutes?.includes('mesabanking') ? [
      {
        path: '/report/mesabanking',
        element: <DashboardLayout />,
        children: [
          { element: <MesaBanking />, index: true },
        ]
      }
    ] : [],

    {
      path: 'filesmanagement',
      element: <DashboardLayout />,
      children: [
        { element: <FilesManagement />, index: true },
      ]
    },

    ...userDepartment === 'DADOS' || user?.attributes?.['custom:api_xp_enabled'] === 'TRUE' ? [
      {
        path: 'apixp',
        element: <DashboardLayout />,
        children: [
          { element: <APIXP />, index: true },
        ]
      }
    ] : [],

    ...userRoutes?.includes('aderenciacarteira') ? [
      {
        path: '/report/produtos',
        element: <DashboardLayout />,
        children: [
          { path: 'aderencia', element: <AderenciaCarteira /> },
        ]
      }
    ] : [],

    // BACKOFFICE
    {
      path: 'backoffice',
      element: <DashboardLayout />,
      children: [
        { path: 'tickets', element: <FollowUp /> },
        { path: 'newticket', element: <NewTicket /> }
      ]
    },

    {
      path: 'ti',
      element: <DashboardLayout />,
      children: [
        { path: 'tickets', element: <FollowUpTI /> },
        { path: 'newticket', element: <NewTicketTI /> }
      ]
    },

    {
      path: 'vcs',
      element: <DashboardLayout />,
      children: [
        { path: 'tickets', element: <FollowUpVCS /> },
        { path: 'newticket', element: <NewTicketVCS /> }
      ]
    },

    {
      path: 'vgr',
      element: <DashboardLayout />,
      children: [
        { path: 'consolidada', element: <ConsolidadaVGR /> },
      ]
    },

    {
      path: 'events',
      element: <DashboardLayout />,
      children: [
        { path: 'list', element: <EventControl /> },
        ...user?.attributes?.['custom:department'] === 'DADOS' || user?.attributes?.['custom:events_enabled'] === 'TRUE' ? [
          { path: 'new', element: <EventForm /> },
          { path: ':id/edit', element: <EventForm isEdit /> },
          { path: ':id/participants', element: <Participants /> },
        ] : [],
      ]
    },

    ...userDepartment === 'PREV' || (userDepartment === 'SEG' && userRole === 'HEAD') ? [{
      path: '/report/prevxp',
      element: <DashboardLayout />,
      children: [
        { element: <PrevXP />, index: true },
      ]
    }] : [],

    ...userDepartment === 'RV' ? [{
      path: '/report/brokers',
      element: <DashboardLayout />,
      children: [
        { element: <Brokers />, index: true },
      ]
    }] : [],

    // ...userRoutes?.includes('relatorion2') ? [{
    //   path: '/report/reunioes/newN3',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <NewN3 />, index: true },
    //   ]
    // }] : [],

    {
      path: 'files',
      element: <DashboardLayout />,
      children: [
        { path: 'list', element: <FilesPage /> },
        ...userDepartment === 'DADOS' || (user?.attributes?.['custom:documents_enabled'] === 'TRUE' && userDepartment === 'RH') ? [
          { path: 'edit', element: <FilesCreate /> },
        ] : [],
      ]
    },

    ...(userDepartment === 'DADOS' || userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('rkyc')) ? [
      {
        path: 'rkyc',
        element: <DashboardLayout />,
        children: [
          { path: 'form', element: <ClientCreate /> },
          { path: ':id/:officer/edit', element: <ClientCreate isEdit /> },
          { path: 'list', element: <ClientList /> },
        ]
      }
    ] : [],

    {
      path: 'store',
      element: <DashboardLayout />,
      children: [
        { path: 'catalog', element: <Catalog /> },
        { path: 'product/:id/:title', element: <Product /> },
        { path: 'cart', element: <Cart /> },
        { path: 'myorders', element: <MyOrders /> },
        ...(userDepartment === 'DADOS' || userDepartment === 'MKT') ? [
          { path: 'new', element: <ProductForm /> },
          { path: ':id/edit', element: <ProductForm isEdit /> },
          { path: 'list', element: <ProductsList isEdit /> },
          { path: 'control', element: <StoreControl /> },
        ] : [],
      ]
    },

    ...(userDepartment === 'DADOS' || userDepartment === 'MKT') ? [
      {
        path: 'club',
        element: <DashboardLayout />,
        children: [
          { path: 'new', element: <ClubItemForm /> },
          { path: ':id/edit', element: <ClubItemForm isEdit /> },
          { path: 'list', element: <ClubItemsList /> },
        ]
      }
    ] : [],

    // {
    //   path: 'portfolio',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Portfolio />, index: true },
    //   ]
    // },

    // CEV
    ...userRoutes?.includes('rankingsxp') ? [
      {
        path: '/report/rankingsxp',
        element: <DashboardLayout />,
        children: [
          { element: <RankingsXP />, index: true }
        ]
      },
      {
        path: '/report/wealthvgr',
        element: <DashboardLayout />,
        children: [
          { element: <WealthVGR />, index: true }
        ]
      },
      {
        path: 'report',
        element: <DashboardLayout />,
        children: [
          { path: 'sdrleads', element: <SdrLeads /> },
        ],
      }
    ] : [],

    //  VCS
    ...(userRoutes?.includes('gerencialvcs')) ? [
      {
        path: '/report/gerencialvcs',
        element: <DashboardLayout />,
        children: [
          { element: <GerencialVCS />, index: true }
        ]
      }] : [],

    // PRODUTOS 
    ...(['RV', 'ASSES', 'DIGI', 'DADOS', 'PROD', 'LUMI', 'BACKOFF', 'SEG', 'PREV', 'VCS', 'PJ', 'SIMPLES', 'MELCONT'].includes(userDepartment) ||
      user?.attributes?.['custom:products_enabled'] === 'TRUE') ? [
      {
        path: 'products',
        element: <DashboardLayout />,
        children: [
          { path: 'rf', element: <RendaFixa /> },
          { path: 'rv', element: <RendaVariavel /> },
          { path: 'contents', element: <Contents /> },
          { path: 'offers', element: <Offers /> },
          ...(userDepartment === 'DADOS' || user?.attributes?.['custom:products_enabled'] === 'TRUE' || userRoutes?.includes('productsCrud')) ? [
            { path: 'newcontent', element: <NewContent /> },
            { path: ':id/edit', element: <NewContent isEdit /> }
          ] : [],
          ...(userDepartment === 'DADOS' || userRoutes?.includes('acessosalocacao')) ? [{ path: 'acessos', element: <AcessosProdutos /> }] : [],
          { path: 'content/:id', element: <ContentPage /> },
          { path: 'fundos', element: <FundosPrioritarios /> },
          { path: 'fiis', element: <FIIs /> },
          { path: 'prev', element: <Prev /> },
          { path: 'cambio', element: <CambioProdutos /> },
          { path: 'internacionais', element: <Internacionais /> },
          { path: 'lumi', element: <Lumi /> },
          { path: 'alternativos', element: <Alternativos /> },
          { path: 'alocacao', element: <Alocacao /> },
          { path: 'aquisicao', element: <Aquisicao /> },
        ]
      }
    ] : [],

    // TRAININGS
    ...(userDepartment === 'DADOS' || userDepartment === 'MELCONT' || userRoutes?.includes('trainings')) ? [
      {
        path: 'training',
        element: <DashboardLayout />,
        children: [
          { path: 'list', element: <TrainingList /> },
          { path: 'officers', element: <TrainingOfficers /> },
          { path: 'new', element: <TrainingForm /> },
          { path: ':classId/:training/edit', element: <TrainingForm isEdit /> },
          { path: 'class/new', element: <ClassForm /> },
          { path: 'class/:id/edit', element: <ClassForm isEdit /> },
          { path: 'classes', element: <Classes /> },
          { path: 'officer', element: <Officer /> },
          { path: 'meet/new', element: <MeetingForm /> },
          { path: 'meet/:meetId/edit', element: <MeetingForm isEdit /> },
          { path: 'meets', element: <MeetsList /> },
          { path: 'modules', element: <ModulesList /> }
        ]
      }
    ] : [],

    // COMPROMISSADAS
    ...(['RV', 'ASSES', 'DIGI', 'DADOS', 'PROD', 'LUMI', 'BACKOFF', 'PJ'].includes(userDepartment) ||
      user?.attributes?.['custom:compromissad_enabled'] === 'TRUE') ? [
      {
        path: 'compromissadas',
        element: <DashboardLayout />,
        children: [
          { path: 'rule', element: <ConfigForm /> },
          { path: ':client/:createdAt/get', element: <ConfigForm isEdit /> },
          { path: 'list', element: <ListConfigs /> },
          { path: 'orders', element: <ListOrders /> },
        ]
      }
    ] : [],

    // COMERCIAL PLANNING
    ...(userDepartment === 'DADOS') ? [
      {
        path: 'comercial-planning',
        element: <DashboardLayout />,
        children: [
          { element: <ComercialPlanning />, index: true },
          { path: ':officer/:createdAt/edit', element: <PlanEdit /> },
          { path: 'list', element: <Plannings /> }
        ]
      }
    ] : [],

    ...(userDepartment === 'DADOS' || userRoutes?.includes('internaltransfers')) ? [
      {
        path: 'internaltransfers',
        element: <DashboardLayout />,
        children: [
          { element: <InternalTransfers />, index: true },
        ]
      },
      {
        path: 'transferorders',
        element: <DashboardLayout />,
        children: [
          { element: <TransferOrders />, index: true },
        ]
      }
    ] : [],

    ...(userDepartment === 'DADOS' || user?.attributes?.['custom:nat_schedule_enabled'] === 'TRUE') ? [{
      path: 'nationalschedule',
      element: <DashboardLayout />,
      children: [
        { element: <NationalSchedule />, index: true },
      ]
    }] : [],

    ...(userDepartment === 'DADOS' || user?.attributes?.['custom:actcampaigns_enabled'] === 'TRUE') ? [
      {
        path: 'activecampaigns',
        element: <DashboardLayout />,
        children: [
          { path: 'list', element: <ActiveCampaigns /> },
          { path: 'new', element: <NewActiveCampaigns /> },
          { path: ':id/edit', element: <NewActiveCampaigns isEdit /> },
        ]
      }] : [],

    ...(userDepartment === 'DADOS' || userRoutes?.includes('painelprivate')) ? [
      {
        path: 'report/private',
        element: <DashboardLayout />,
        children: [
          { element: <Private />, index: true },
        ]
      }
    ] : [],

    ...userDepartment === 'ASSES' || userDepartment === 'DIGI' || (userDepartment === 'RV' && userRole === 'EXPERT') ? [{
      path: 'report/performance',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/performance/feefixo" replace />, index: true },
        { path: 'feefixo', element: <FeeFixo /> },
      ],
    }] : [],

    // Power Bi exportable
    ...powerBiExport || userRoutes?.includes('powerBiExport') ? [{
      path: 'export',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/export/aai" replace />, index: true },
        { path: 'aai', element: <AAI /> },
        ...userRoutes?.includes('exportbasesclientes') ? [{ path: 'basesclientes', element: <BasesClientes /> }] : [],
      ],
    }] : [],

    // ...(user?.attributes?.['custom:core'] === 'VI' || user?.attributes?.['custom:core'] === 'VCS') ? [
    //   {
    //     path: 'ativometro/pj',
    //     element: <DashboardLayout />,
    //     children: [
    //       { path: 'ranking', element: <AtivometroPjRanking /> },
    //       ...((user?.attributes?.['custom:core'] === 'VI' && userRole === 'ADVISOR') || ((userDepartment === 'ASSES' || userDepartment === 'SEG') && userRole === 'HEAD')) ?
    //         [{ path: 'aai', element: <AtivometroPjAAI /> }] : [],
    //     ],
    //   }
    // ] : [],

    // OFF SHORE
    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('gerencialoffshoreaais')) ?
      [{
        path: 'offshore',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/offshore/gerencialaai" replace />, index: true },
          { path: 'gerencialaai', element: <GerencialOffShoreAAI /> },
          ...userRoutes?.includes('workingoffshore') ? [{ path: 'working', element: <WorkingOffShore /> }] : [],
        ]
      }] : [],

    // PIPE
    ...pipeAdvisor || pipeLeader || pipeManager || pipeHead ?
      [{
        path: 'pipe',
        element: <DashboardLayout />,
        children: [
          // { element: <Navigate to="/pipe/" replace />, index: true },
          ...pipeAdvisor || pipeLeader || pipeManager || (userDepartment === 'MELCONT' && userRole === 'HEAD') ? [{ path: 'advisor', element: <Pipe /> }] : [],
          ...pipeLeader ? [{ path: 'leader', element: <PipeLeader /> }] : [],
          ...pipeManager ? [{ path: 'manager', element: <PipeManager /> }] : [],
          ...pipeHead ? [{ path: 'head', element: <PipeHead /> }] : [],
          { path: 'fup', element: <PipeFUP /> }
        ]
      }] : [],

    {
      path: 'announcements',
      element: <DashboardLayout />,
      children: [
        { path: 'all', element: <Announcements /> },
        { path: 'announcement/:id', element: <Announcement /> },
      ]
    },

    // OKR
    ...(userRole === 'MANAGER' || userRoutes?.includes('okrgu')) ?
      [{
        path: 'okr',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/okr/follow-up" replace />, index: true },
          {
            path: 'follow-up',
            children: [
              { element: <Navigate to="/okr/follow-up/gu" replace />, index: true },
              { path: 'gu', element: <OKRGU /> },
              // ...(isAssess && ((userRole === "ADVISOR") || (userRole === 'DEV') || (userRole === 'LEADER'))) ? [{ path: 'advisor', element: <OKRAdvisor /> }] : [],
              // ...(isAssess && ((userRole === "LEADER") || (userRole === 'DEV'))) ? [{ path: 'leader', element: <OKRLeader /> }] : [],
            ]
          },
          // {
          //   path: 'about',
          //   element: <AboutOKR />,
          // },
        ],
      }] : [],

    // SUPER RANKING
    {
      path: 'superranking',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/superranking/general" replace />, index: true },
        { path: 'general', element: <SuperRanking /> },
        ...isAssess ? [{ path: 'individual', element: <SuperRankingIndividual /> }] : [],
      ]
    },

    // NPS
    // ...(userDepartment === 'MELCONT' && userRole === 'HEAD') ?
    //   [{
    //     path: 'nps',
    //     element: <DashboardLayout />,
    //     children: [
    //       { element: <Navigate to="/nps/medallia" replace />, index: true },
    //       { path: 'medallia', element: <NPSMedallia /> },
    //     ]
    //   }] : [],

    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('npsmedalliaaais')) ? [
      {
        path: 'nps',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/nps/medalliaaai" replace />, index: true },
          { path: 'medalliaaai', element: <NPSMedalliaAAI /> },
        ]
      }
    ] : [],

    // COMPROMISSADAS
    ...((userDepartment === 'ASSES' || userDepartment === 'DIGI') && (userRole === 'ADVISOR' || userRole === 'LEADER' || userRole === 'FARMER'))
      || userRoutes?.includes('compromissadas') ? [
      {
        path: 'report/compromissadas',
        element: <DashboardLayout />,
        children: [
          { element: <Compromissadas />, index: true },
        ]
      }
    ] : [],
    ...(((userDepartment === 'ASSES' || userDepartment === 'DIGI') && (userRole === 'MANAGER' || userRole === 'HEAD'))
      || userRoutes?.includes('compromissadasgu')) ? [
      {
        path: 'report/compromissadas',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/report/compromissadas/gu" replace />, index: true },
          { path: 'gu', element: <CompromissadasGU /> },
        ]
      }
    ] : [],

    // METAS MASTER
    ...(assesManager) || userRoutes?.includes('metasmaster') ?
      [{
        path: 'report/metas',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/report/metas/master" replace />, index: true },
          { path: 'master', element: <MetasMaster /> },
        ]
      }] : [],

    // DASHBOARD
    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || (userDepartment === 'RV' && userRole === 'EXPERT') || userRoutes?.includes('specialDashboard')) ? [{
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/base" replace />, index: true },
        { path: 'base', element: <GeneralBase /> },
        { path: 'campaigns', element: <CampaignsList /> },
      ],
    }] : [],

    // USUÁRIOS
    ...userRoutes?.includes('users') || user?.attributes?.['custom:read_users_enabled'] === 'TRUE' ? [{
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':username/edit', element: <UserCreate /> },
          ],
        },
      ],
    }] : [],

    ...userDepartment === 'RV' || userRoutes?.includes('trocaativos') ?
      [{
        path: 'report',
        element: <DashboardLayout />,
        children: [
          { path: 'trocaativos', element: <TrocaAtivos /> },
        ],
      }] : [],

    // Payback AAI
    ...userRoutes?.includes('paybackaai') ? [{
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/paybackaai" replace />, index: true },
        { path: 'paybackaai', element: <PaybackAAI /> },
      ],
    }] : [],

    // Rankinkg Gerais
    ...(assesManager) || userRoutes?.includes('rankingsgeraisgu') ? [
      {
        path: 'report',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/report/rankingsgerais" replace />, index: true },
          { path: 'rankingsgerais', element: <RankingsGerais /> },
        ],
      }
    ] : [],

    ...userRoutes?.includes('historicocliente') ?
      [{
        path: 'report',
        element: <DashboardLayout />,
        children: [
          { path: 'clienthistory', element: <ClientHistory /> },
        ],
      }] : [],

    ...userRoutes?.includes('sdrleads') ?
      [
        {
          path: 'report',
          element: <DashboardLayout />,
          children: [
            { path: 'sdrleads', element: <SdrLeads /> },
          ],
        }
      ] : [],

    // CAMPANHAS
    // ...user?.attributes?.['custom:core'] === 'VI' ? [{
    //   path: 'campaigns',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/campaigns/ativacao300k" replace />, index: true },
    //     {
    //       path: 'ativacao300k',
    //       children: [
    //         { element: <Navigate to="/campaigns/ativacao300k/ranking" replace />, index: true },
    //         { path: 'ranking', element: <Ativacao300kRanking /> },
    //         { path: 'consulta', element: <Ativacao300kConsulta /> },
    //       ]
    //     },
    //   ],
    // }] : [],

    ...userRoutes?.includes('creditoxp') ?
      [
        {
          path: 'report',
          element: <DashboardLayout />,
          children: [
            { path: 'creditoxp', element: <CreditoXP /> },
          ],
        }
      ] : [],

    ...assesManager || userRoutes?.includes('eventosmkt') ?
      [
        {
          path: 'report',
          element: <DashboardLayout />,
          children: [
            { path: 'events', element: <Events /> },
          ],
        }
      ] : [],

    // GU
    ...(assesManager) || (userRoutes?.includes('gerencialgu')) || assesHead ? [{
      path: 'gu',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/gu/bases" replace />, index: true },
        ...(assesManager || userRoutes?.includes('basesgu')) ? [{ path: 'bases', element: <BasesGU /> }] : [],
        ...(userRoutes?.includes('basesdigital')) ? [{ path: 'basesdigital', element: <BasesGUDigital /> }] : [],
        ...(assesManager || userRoutes?.includes('booktatico')) ? [{ path: 'booktatico', element: <BookTatico /> }] : [],
        ...(assesManager || userRoutes?.includes('acessos')) ? [{ path: 'acessos', element: <Acessos /> }] : [],
        ...(assesManager || userRoutes?.includes('campanhascrm')) ? [{ path: 'campanhascrm', element: <CampanhasCRM /> }] : [],
        ...(assesManager || userRoutes?.includes('gerencialgu')) ? [{ path: 'gerencialgu', element: <Gerencial /> }] : [],
        ...(assesManager || userRoutes?.includes('oportunidadesativacaogu')) ? [{ path: 'oportunidadesativacaogu', element: <OportunidadesAtiv /> }] : [],
        ...(assesManager || userRoutes?.includes('previdenciagu')) ? [{ path: 'previdenciagu', element: <Previdencia /> }] : [],
        ...(assesManager || userRoutes?.includes('superrankinggu')) ? [{ path: 'superrankinggu', element: <SuperRankingGU /> }] : [],
        ...(assesManager || userRoutes?.includes('indicadoresg20')) ? [{ path: 'indicadores', element: <Indicadores /> }] : [],
        ...(assesManager || userRoutes?.includes('n2gu')) ? [{ path: 'n2', element: <N2 /> }] : [],
        ...(assesManager || userRoutes?.includes('superrankinglideresgu')) ? [{ path: 'superrankinglideresgu', element: <SuperRankingLeaderGU /> }] : [],
        ...((assesManager && userBranch === 'DIGITAL') || userRoutes?.includes('digitalgu')) ? [{ path: 'gudigi', element: <GUDigi /> }] : [],
        ...(assesManager || userRoutes?.includes('basecambiogu')) ? [{ path: 'cambio', element: <Cambio /> }] : [],
        ...(assesManager || assesHead || userRoutes?.includes('metasgu')) ? [{ path: 'metas', element: <MetasGU /> }] : [],
        ...(userDepartment !== 'ASSES') ? [{ path: 'feefixo', element: <FeeFixo /> }] : [],
        { path: 'receita', element: <ReceitaManager /> },
        // { path: 'rankingsxp', element: <RankingsXP /> },
        { path: 'xsell', element: <CrossSell /> },
      ],
    }] : [],

    ...(userDepartment === 'ASSES' && (userRole === 'ADVISOR' || userRole === 'MANAGER' || userRole === 'LEADER')) ? [
      {
        path: 'report/performance',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/report/performace/rating" replace />, index: true },
          ...(userRole === 'ADVISOR' || userRole === 'LEADER') ?
            [
              {
                path: 'xsell',
                element: <CrossSell />
              },
              {
                path: 'rating',
                element: <CicloAvaliativo />
              }
            ] : [],
          ...(userRole === 'MANAGER') ?
            [
              {
                path: 'rating',
                element: <CicloAvaliativoGU />
              },
              {
                path: 'ratingleadermanager',
                element: <CicloAvaliativoLeaderGU />
              }
            ] : [],
          ...(userRole === 'LEADER') ?
            [
              {
                path: 'ratingleader',
                element: <CicloAvaliativoLeader />
              },
              {
                path: 'ratingleaderself',
                element: <CicloAvaliativoLeaderSelf />
              }
            ] : [],
        ]
      }] : [],

    ...(userDepartment === 'RH' || (userRoutes?.includes('performancesemestralaaiexport'))) ? [
      {
        path: 'report/performance',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/report/performance/rating" replace />, index: true },
          {
            path: 'rating',
            element: <CicloAvaliativoExport />
          },
          {
            path: 'ratingleader',
            element: <CicloAvaliativoLeaderExport />
          },
        ]
      }] : [],

    ...(userRoutes?.includes('roagestor')) ? [
      {
        path: 'gu',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/receita" replace />, index: true },
          {
            path: 'receita',
            element: <ReceitaManager />
          },
        ]
      }] : [],

    // PJ
    ...(userRoutes?.includes('gerencialpj')) ? [{
      path: 'pj',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/pj/performance" replace />, index: true },
        {
          path: 'performance',
          children: [
            { element: <Navigate to="/pj/performance/gerencial" replace />, index: true },
            { path: 'gerencial', element: <GerencialPJ /> },
            { path: 'bases', element: <BasesPJ /> },
          ]
        },
      ]
    }] : [],

    // Tools
    {
      path: 'tools',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/tools/find" replace />, index: true },

        { path: 'find', element: <FindClient /> },

        { path: 'links', element: <Links /> },

        { path: 'scheduler', element: <SchedulerV2 /> },

        {
          path: 'rooms',
          children: [
            { element: <Navigate to="/rooms/list" replace />, index: true },
            { path: 'list', element: <RoomList /> },
            ...userDepartment === 'ADMIN' || userDepartment === 'DADOS' ? [
              { path: 'new', element: <RoomNew /> },
              { path: ':id/:branch_/edit', element: <RoomNew /> }
            ] : []
          ]
        },
        {
          path: 'gv',
          children: [
            { element: <Navigate to="/tools/gv/control" replace />, index: true },
            { path: 'control/:branch/:playlist', element: <GestaoAVistaADM /> },
            { path: 'monitoring', element: <Monitoring /> },
            { path: 'playlists', element: <Playlists /> },
            // { path: 'fullscreen', element: <GestaoAVista /> },
          ]
        },

        ...uploadEnabled ? [{
          path: 'upload',
          children: [
            { element: <Navigate to="/tools/upload/list" replace />, index: true },
            { path: 'list', element: <Uploads /> },
            ...isAdmin ? [
              { path: 'new', element: <Upload /> },
              { path: ':baseKey/edit', element: <Upload /> }
            ] : [],
          ],
        }] : [],

        {
          path: 'sharing',
          children: [
            { element: <Navigate to="/tools/sharing/list" replace />, index: true },
            { path: 'list', element: <Posts /> },
            { path: 'post/:postid', element: <Post /> },
            ...isAdmin ? [{ path: 'all', element: <OldPosts /> }] : [],
            ...sharingEnabled ? [
              { path: 'new', element: <NewPost /> },
              { path: 'post/:postid/edit', element: <NewPost /> }
            ] : [],
          ],
        },
      ],
    },

    {
      path: 'tools/gv',
      children: [
        { path: 'fullscreen', element: <GestaoAVista /> },
      ]
    },

    // Requests
    // ...isAssess || userRole === 'MANAGER' ? [{
    //   path: 'requests',
    //   element: <DashboardLayout />,
    //   children: [
    //     {
    //       path: 'transfer',
    //       children: [
    //         { element: <Navigate to="/requests/transfer/new" replace />, index: true },
    //         { path: 'new', element: <TransferRequest /> },
    //         { path: 'follow-up', element: <TransferFollowUp /> },
    //         { path: ':transferid/edit', element: <TransferRequest /> },
    //         ...isAdmin ? [{ path: 'history', element: <TransferHistory /> }] : [],
    //       ],
    //     },
    //   ],
    // }] : [],

    // Self profile
    {
      path: 'in',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/in/account" replace />, index: true },
        { path: 'account', element: <UserAccount /> },
        { path: 'welcome', element: <Welcome /> },
      ],
    },

    // Main Routes
    {
      path: '/update',
      element: <LogoOnlyLayout content />,
      children: [
        { element: <AccountUpdate />, index: true }
      ]
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Redirect /> },
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/', element: <Navigate to={defaultRoute || "/in/welcome"} replace /> },
    // { path: '*', element: <Navigate to="/404" replace /> },
    { path: '*', element: <Redirect /> },
  ];
}

// IMPORT COMPONENTS
const Home = Loadable(lazy(() => import('../pages/general/Home')));
const NationalSchedule = Loadable(lazy(() => import('../pages/VI/DADOS/test/NationalSchedule')));
const InternalTransfers = Loadable(lazy(() => import('../pages/VI/general/transfer/InternalTransfers')));
const TransferOrders = Loadable(lazy(() => import('../pages/VI/general/transfer/TransferOrders')));
const Download = Loadable(lazy(() => import('../pages/general/Download')));
const EventControl = Loadable(lazy(() => import('../pages/general/events/EventControl')));
const EventForm = Loadable(lazy(() => import('../pages/general/events/EventForm')));
const Participants = Loadable(lazy(() => import('../pages/general/events/ParticipantsForm')));

// COMERCIAL PLANNING
const ComercialPlanning = Loadable(lazy(() => import('../pages/VI/general/comercial-planning/CommercialPlanning')));
const PlanEdit = Loadable(lazy(() => import('../pages/VI/general/comercial-planning/PlanEdit')));
const Plannings = Loadable(lazy(() => import('../pages/VI/general/comercial-planning/Plannings')));

// MESA BANKING
const MesaBanking = Loadable(lazy(() => import('../pages/VI/general/MesaBanking')));

// RKYC
const ClientCreate = Loadable(lazy(() => import('../pages/VI/general/RKYC/ClientCreate')));
const ClientList = Loadable(lazy(() => import('../pages/VI/general/RKYC/ClientList')));

// DOCUMENTS
const FilesPage = Loadable(lazy(() => import('../pages/general/files/FilesPage')));
const FilesCreate = Loadable(lazy(() => import('../pages/general/files/FilesCreate')));

// Power Bi exportable
const AAI = Loadable(lazy(() => import('../pages/general/power-bi/export/AAI')));
const BasesClientes = Loadable(lazy(() => import('../pages/general/power-bi/export/BasesClientes')));

// VCS
const GerencialVCS = Loadable(lazy(() => import('../pages/VCS/general/power-bi/GerencialVCS')));

// VGR
const ConsolidadaVGR = Loadable(lazy(() => import('../pages/VGR/general/ConsolidadaVGR')));

// STORE
const Catalog = Loadable(lazy(() => import('../pages/general/valor-store/Catalog')));
const Product = Loadable(lazy(() => import('../pages/general/valor-store/Product')));
const Cart = Loadable(lazy(() => import('../pages/general/valor-store/Cart')));
const MyOrders = Loadable(lazy(() => import('../pages/general/valor-store/MyOrders')));
const ProductForm = Loadable(lazy(() => import('../pages/general/valor-store/ItemForm')));
const ProductsList = Loadable(lazy(() => import('../pages/general/valor-store/ProductsList')));
const StoreControl = Loadable(lazy(() => import('../pages/general/valor-store/StoreControl')));

// COMPROMISSADAS
const ConfigForm = Loadable(lazy(() => import('../pages/VI/general/compromissadas/ConfigForm')));
const ListConfigs = Loadable(lazy(() => import('../pages/VI/general/compromissadas/ListConfigs')));
const ListOrders = Loadable(lazy(() => import('../pages/VI/general/compromissadas/ListOrders')));

const TrainingList = Loadable(lazy(() => import('../pages/general/tools/training/TrainingList')));
const TrainingOfficers = Loadable(lazy(() => import('../pages/general/tools/training/Officers')));
const TrainingForm = Loadable(lazy(() => import('../pages/general/tools/training/TrainingForm')));
const Classes = Loadable(lazy(() => import('../pages/general/tools/training/Classes')));
const ClassForm = Loadable(lazy(() => import('../pages/general/tools/training/ClassForm')));
const Officer = Loadable(lazy(() => import('../pages/general/tools/training/Officer')));
const MeetingForm = Loadable(lazy(() => import('../pages/general/tools/training/MeetingForm')));
const MeetsList = Loadable(lazy(() => import('../pages/general/tools/training/MeetsList')));
const ModulesList = Loadable(lazy(() => import('../pages/general/tools/training/ModulesList')));

// CLUB
const ClubItemForm = Loadable(lazy(() => import('../pages/general/valor-club/ItemForm')));
const ClubItemsList = Loadable(lazy(() => import('../pages/general/valor-club/ItemsList')));

// BACKOFFICE
const FollowUp = Loadable(lazy(() => import('../pages/general/backoffice-tickets/FollowUp')));
const NewTicket = Loadable(lazy(() => import('../pages/general/backoffice-tickets/NewTicket')));

// VCS TICKETS
const FollowUpVCS = Loadable(lazy(() => import('../pages/general/vcs-tickets/FollowUp')));
const NewTicketVCS = Loadable(lazy(() => import('../pages/general/vcs-tickets/NewTicket')));

// VI
const FollowUpTI = Loadable(lazy(() => import('../pages/general/ti-tickets/FollowUp')));
const NewTicketTI = Loadable(lazy(() => import('../pages/general/ti-tickets/NewTicket')));

const FilesManagement = Loadable(lazy(() => import('../pages/general/tools/files-management/FilesManagement')));
const APIXP = Loadable(lazy(() => import('../pages/general/APIXP')));

// PRODUCTS
const RendaFixa = Loadable(lazy(() => import('../pages/general/products/RendaFixa')));
const RendaVariavel = Loadable(lazy(() => import('../pages/general/products/RendaVariavel')));
const NewContent = Loadable(lazy(() => import('../pages/general/products/NewContent')));
const Contents = Loadable(lazy(() => import('../pages/general/products/Contents')));
const Offers = Loadable(lazy(() => import('../pages/general/products/Offers')));
const AcessosProdutos = Loadable(lazy(() => import('../pages/general/products/Acessos')));
const FundosPrioritarios = Loadable(lazy(() => import('../pages/general/products/Fundos')));
const FIIs = Loadable(lazy(() => import('../pages/general/products/FIIs')));
const Prev = Loadable(lazy(() => import('../pages/general/products/Previdencia')));
const CambioProdutos = Loadable(lazy(() => import('../pages/general/products/Cambio')));
const Internacionais = Loadable(lazy(() => import('../pages/general/products/Internacionais')));
const Lumi = Loadable(lazy(() => import('../pages/general/products/Lumi')));
const Alternativos = Loadable(lazy(() => import('../pages/general/products/Alternativos')));
const Alocacao = Loadable(lazy(() => import('../pages/general/products/Alocacao')));
const Aquisicao = Loadable(lazy(() => import('../pages/general/products/Aquisicao')));
const ContentPage = Loadable(lazy(() => import('../pages/general/products/ContentPage')));

// Ativometro
// const AtivometroPjAAI = Loadable(lazy(() => import('../pages/general/power-bi/AtivometroPjAAI')));
// const AtivometroPjRanking = Loadable(lazy(() => import('../pages/general/power-bi/AtivometroPjRanking')));
// PIPE
const Pipe = Loadable(lazy(() => import('../pages/VI/general/pipe/advisor/Pipe')));
const PipeLeader = Loadable(lazy(() => import('../pages/VI/general/pipe/leader/PipeLeader')));
const PipeManager = Loadable(lazy(() => import('../pages/VI/general/pipe/manager/PipeManager')));
const PipeHead = Loadable(lazy(() => import('../pages/VI/general/pipe/head/PipeHead')));
const PipeFUP = Loadable(lazy(() => import('../pages/VI/general/pipe/PipeFUP')));

// GV
const GestaoAVista = Loadable(lazy(() => import('../pages/general/tools/gestaoAVista/GestaoAVista')));
const GestaoAVistaADM = Loadable(lazy(() => import('../pages/general/tools/gestaoAVista/GestaoAVistaADM')));
const Monitoring = Loadable(lazy(() => import('../pages/general/tools/gestaoAVista/Monitoring')));
const Playlists = Loadable(lazy(() => import('../pages/general/tools/gestaoAVista/Playlists')));

// OKR
// const OKR = Loadable(lazy(() => import('../pages/VI/general/okr/OKRGeneral')));
// const AboutOKR = Loadable(lazy(() => import('../pages/VI/general/okr/About')));
// const OKRLeader = Loadable(lazy(() => import('../pages/VI/general/okr/OKRLeader')));
// const OKRAdvisor = Loadable(lazy(() => import('../pages/VI/general/okr/OKRAdvisor')));
const OKRGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/OKR')));

// SUPER RANKING
const SuperRanking = Loadable(lazy(() => import('../pages/VI/general/superranking/SuperRanking')));
const SuperRankingIndividual = Loadable(lazy(() => import('../pages/VI/general/superranking/SuperRankingIndividual')));

// DASHBOARD
const GeneralBase = Loadable(lazy(() => import('../pages/VI/general/GeneralBase')));
const CampaignsList = Loadable(lazy(() => import('../pages/VI/general/campaigns/CampaignsList')));

// USUÁRIOS
// user
const UserList = Loadable(lazy(() => import('../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/VI/general/user/UserCreate')));

// REPORT
const Events = Loadable(lazy(() => import('../pages/VI/EVENTOS/general/power-bi/Dashboard')));
const FeeFixo = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/performance/FeeFixo')));
const CreditoXP = Loadable(lazy(() => import('../pages/general/power-bi/CreditoXP')));
const PaybackAAI = Loadable(lazy(() => import('../pages/VI/general/power-bi/PaybackAAI')));
const ClientHistory = Loadable(lazy(() => import('../pages/VI/MKT/general/ClientHistory')));
const TrocaAtivos = Loadable(lazy(() => import('../pages/VI/RV/general/power-bi/TrocaAtivos')));
const SdrLeads = Loadable(lazy(() => import('../pages/VI/MKT/general/SdrLeads')));
const RankingsGerais = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/ranking/RankingsGerais')));
// const Ativacao300kRanking = Loadable(lazy(() => import('../pages/VI/general/power-bi/campaign/ativacao300k/Ranking')));
// const Ativacao300kConsulta = Loadable(lazy(() => import('../pages/VI/general/power-bi/campaign/ativacao300k/Consulta')));
const ReceitaManager = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/performance/ReceitaManager')));
const CrossSell = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CrossSell')));
const CicloAvaliativo = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CicloAvaliativo')));
const CicloAvaliativoLeader = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CicloAvaliativoLeader')));
const CicloAvaliativoLeaderSelf = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CicloAvaliativoLeaderSelf')));
const CicloAvaliativoGU = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CicloAvaliativoGU')));
const CicloAvaliativoLeaderGU = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/CicloAvaliativoLeaderGU')));
const CicloAvaliativoExport = Loadable(lazy(() => import('../pages/VI/general/power-bi/CicloAvaliativo')));
const CicloAvaliativoLeaderExport = Loadable(lazy(() => import('../pages/VI/general/power-bi/CicloAvaliativoLeader')));
const MetasMaster = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/MetasMaster')));
const Brokers = Loadable(lazy(() => import('../pages/VI/RV/general/power-bi/Brokers')));
const PrevXP = Loadable(lazy(() => import('../pages/general/power-bi/PrevXP')));
const Private = Loadable(lazy(() => import('../pages/VI/general/power-bi/Private')));
const AderenciaCarteira = Loadable(lazy(() => import('../pages/VI/PROD/general/power-bi/AderenciaCarteira')));

// OFF SHORE
// const GerencialOffShore = Loadable(lazy(() => import('../pages/VI/general/power-bi/GerencialOffShore')));
const GerencialOffShoreAAI = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/GerencialOffshoreAAI')));
const WorkingOffShore = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/WorkingOffShore')));

// COMPROMISSADAS
const Compromissadas = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/Compromissadas')));
const CompromissadasGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/CompromissadasGU')));
// GU
const BasesGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Bases')));
const BookTatico = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/BookTatico')));
const Gerencial = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Gerencial')));
const OportunidadesAtiv = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/OportunidadesAtiv')));
const Previdencia = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Previdencia')));
const SuperRankingGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/SuperRanking')));
// const AtivMaster = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/AtivMaster')));
// const AtivAsses = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/AtivAsses')));
const CampanhasCRM = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/CampanhasCRM')));
const Acessos = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Acessos')));
const Indicadores = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Indicadores')));
const N2 = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/N2')));
const SuperRankingLeaderGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/SuperRankingLeader')));
// const OKR = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/OKR')));
const GUDigi = Loadable(lazy(() => import('../pages/VI/DIGI/general/power-bi/GU')));
const BasesGUDigital = Loadable(lazy(() => import('../pages/VI/DIGI/general/power-bi/BasesGU')));
const Cambio = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/Cambio')));
const MetasGU = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/MetasGU')));
const RankingsXP = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/RankingsXP')));
const WealthVGR = Loadable(lazy(() => import('../pages/VI/ASSES/MANAGER/power-bi/WealthVGR')));

// PJ
const BasesPJ = Loadable(lazy(() => import('../pages/VI/PJ/general/power-bi/Bases')));
const GerencialPJ = Loadable(lazy(() => import('../pages/VI/PJ/general/power-bi/Gerencial')));

// Tools
const Links = Loadable(lazy(() => import('../pages/general/Links')));
const FindClient = Loadable(lazy(() => import('../pages/VI/general/tools/FindClient')));
// const Scheduler = Loadable(lazy(() => import('../pages/general/tools/scheduler/Scheduler')));
const SchedulerV2 = Loadable(lazy(() => import('../pages/general/tools/scheduler/SchedulerV2')));
const RoomList = Loadable(lazy(() => import('../pages/general/tools/scheduler/RoomList')));
const RoomNew = Loadable(lazy(() => import('../pages/general/tools/scheduler/RoomNew')));
const Announcements = Loadable(lazy(() => import('../pages/general/announcements/Announcements')));
const Announcement = Loadable(lazy(() => import('../pages/general/announcements/Announcement')));
const OrgChart = Loadable(lazy(() => import('../pages/general/orgchart/OrgChart')));
const OrgStructure = Loadable(lazy(() => import('../pages/general/orgchart/OrgStructure')));

// uploads
const Upload = Loadable(lazy(() => import('../pages/general/tools/uploads/Upload')));
const Uploads = Loadable(lazy(() => import('../pages/general/tools/uploads/Uploads')));
// sharing
const Post = Loadable(lazy(() => import('../pages/general/tools/post/Post')));
const Posts = Loadable(lazy(() => import('../pages/general/tools/post/Posts')));
const NewPost = Loadable(lazy(() => import('../pages/general/tools/post/NewPost')));
const OldPosts = Loadable(lazy(() => import('../pages/general/tools/post/OldPosts')));

// Requests
// const TransferRequest = Loadable(lazy(() => import('../pages/VI/general/transfer/TransferRequest')));
// const TransferHistory = Loadable(lazy(() => import('../pages/VI/general/transfer/TransferHistory')));
// const TransferFollowUp = Loadable(lazy(() => import('../pages/VI/general/transfer/TransferFollowUp')));

// NPS
// const NPSMedallia = Loadable(lazy(() => import('../pages/VI/general/power-bi/NPSMedallia')));
const NPSMedalliaAAI = Loadable(lazy(() => import('../pages/VI/ASSES/general/power-bi/NPSMedalliaAAI')));

// Active Campaigns
const NewActiveCampaigns = Loadable(lazy(() => import('../pages/general/campaigns/NewActiveCampaigns')));
const ActiveCampaigns = Loadable(lazy(() => import('../pages/general/campaigns/ActiveCampaigns')));

// Self profile
const Welcome = Loadable(lazy(() => import('../pages/general/Welcome')));
const UserAccount = Loadable(lazy(() => import('../pages/VI/general/UserAccount')));

// Main Routes
const Page500 = Loadable(lazy(() => import('../pages/general/Page500')));
const AccountUpdate = Loadable(lazy(() => import('../pages/general/AccountUpdate')));
const Page403 = Loadable(lazy(() => import('../pages/general/Page403')));
const NotFound = Loadable(lazy(() => import('../pages/general/Page404')));
const Maintenance = Loadable(lazy(() => import('../pages/general/Maintenance')));
const Redirect = Loadable(lazy(() => import('../pages/general/Redirect')));

// Roa
const Roa = Loadable(lazy(() => import('../pages/VI/ASSES/general/Roa')));